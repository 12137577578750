import {checkboxClasses} from '@mui/material/Checkbox';

// ----------------------------------------------------------------------

export function checkbox(theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          [`&.${checkboxClasses.checked}`]: {
            color: '#212B36'
          }
        },
      },
    },
  };
}
