import {api} from '../api'

export const docTemplatesApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchDocTemplates: build.query({

      query(payload) {
        return {
          url: `/document-template/`,
          method: 'GET',
          params: {

            page: payload?.page,
            file_document_type__name: "customer_offer",
            ...(payload.status === 'active'
              ? {is_active: true}
              : payload.status === 'inactive'
                ? {is_active: false}
                : []),
            ...(payload?.endpoint_id?.length > 0 ? {
              endpoints: payload?.endpoint_id?.join(',')
            } : []),
            ...(payload?.text?.length > 0 ? {name__icontains: payload?.text} : [])
          }
        }
      },
    }),

    createDocTemplate: build.query({
      query(payload) {
        const formData = new FormData()

        formData.append("name", payload.name);
        formData.append("file", payload.file);
        formData.append("merchant_id", payload.merchant_id);
        formData.append("check_template", true);

        for (let i = 0; i < payload?.endpoint_ids?.length; i++) {
          formData.append('endpoint_ids', payload.endpoint_ids[i]);
        }
        return {
          url: `/document-template/`,
          method: 'POST',
          headers: {"Content-Type": "multipart/form-data"},
          data: formData
        }
      },
    }),

    updateDocTemplate: build.query({
      query(payload) {
        const formData = new FormData()

        if (payload.name) {
          formData.append("name", payload.name);
        }
        if (payload.file) {
          formData.append("file", payload.file);
        }
        if (payload.merchant_id) {
          formData.append("merchant_id", payload.merchant_id);
        }

        console.log(payload, payload.is_active, Boolean(payload.is_active))
        formData.append("is_active", Boolean(payload.is_active));

        for (let i = 0; i < payload?.endpoint_ids?.length; i++) {
          formData.append('endpoint_ids', payload.endpoint_ids[i]);
        }

        formData.append("check_template", true);

        return {
          url: `/document-template/${payload.id}/`,
          method: 'PUT',
          headers: {"Content-Type": "multipart/form-data"},
          data: formData
        }
      },

    }),

    fetchDocTemplateId: build.query({
      query(payload) {
        return {
          url: `/document-template/${payload.id}/`,
          method: 'GET',
        }
      },
    }),

    deleteDocTemplateId: build.query({
      query(payload) {
        return {
          url: `/document-template/${payload.id}/`,
          method: 'DELETE',
        }
      },
    }),
  }),
})
