import {put, select, takeLatest} from "redux-saga/effects"
import {network} from '../../utils/network'
import {endpointSlice} from './endpoingSlice'

function* fetch() {
  yield put(endpointSlice.actions.setLoading({type: 'endpoints', loading: true}))
  const user = yield select(store => store.user)
  const employeeEndpointId = user?.permissions?.user_data?.employee_endpoint_id
  try {
    const response = yield network.axios({
      method: 'GET',
      url: '/endpoint/',
    })
    yield put(endpointSlice.actions.setEndpoints(response.data))
    const localStorageEndpointId = localStorage.getItem('endpoint')
    const foundLocalStorage = response.data.find(i => i.id === localStorageEndpointId)
    const foundEmployee = response.data.find(i => i.id === employeeEndpointId)

    if (foundLocalStorage) {
      yield put(endpointSlice.actions.selectEndpoint(foundLocalStorage))
    } else if (foundEmployee) {
      yield put(endpointSlice.actions.selectEndpoint(foundEmployee))
    } else {
      yield put(endpointSlice.actions.selectEndpoint(response.data[0]))
    }
  } catch (e) {
    // todo: error?
  }
  yield put(endpointSlice.actions.setLoading({type: 'endpoints', loading: false}))
}

export function* endpointSagas() {
  yield takeLatest(endpointSlice.actions.fetch.type, fetch)
}
