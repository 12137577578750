// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/operations',
  REGISTERS: '/registers',
  EMPLOYEES: '/employees',
  TRANSPORTS: '/transports',
  DOC_TEMPLATES: '/doc-templates',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      // login: `${ROOTS.AUTH}/jwt/login`,
      // register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  registers: {
    root: ROOTS.REGISTERS
  },
  employees: {
    root: ROOTS.EMPLOYEES
  },
  transports: {
    root: ROOTS.TRANSPORTS
  },
  docTemplates: {
    root: ROOTS.DOC_TEMPLATES
  }
};
