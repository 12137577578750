import {api} from '../api'

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    userOtpResetPassword: build.query({
      query(payload) {
        return {
          url: `/otp/check_reset_password/`,
          method: 'POST',
          data: {
            username: payload.username,
            code: payload.code,
          },
        }
      },
    }),
  }),
})
