import {useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

// ----------------------------------------------------------------------

export function useScrollToTop(scroll) {
  const {pathname} = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    scroll && window.scrollTo(0, 0);
  }, [pathname, searchParams.get('step')]);

  return null;
}
