import React from 'react'
import {Box, Button, IconButton, InputAdornment, Typography} from '@mui/material';
import Iconify from 'src/components/iconify';
import {useBoolean} from 'src/hooks/use-boolean';
import {RHFTextField} from 'src/components/hook-form';

export default function GeneratePassword(props) {
  const {loading, generatePassword, passwordValue, passwordRepeatValue} = props

  const password = useBoolean();
  const passwordRepeat = useBoolean();

  return (
    <>
      <Box mt={2.5}>
        <RHFTextField
          name="password"
          label="Пароль*"
          disabled={loading}
          autoComplete="off"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...(passwordValue && {
            value: passwordValue
          })}
        />
      </Box>
      <Box mt={2.5}>
        <RHFTextField
          name="passwordRepeat"
          label="Повторите пароль* "
          disabled={loading}
          type={passwordRepeat.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordRepeat.onToggle} edge="end">
                  <Iconify icon={passwordRepeat.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}

          {...(passwordRepeatValue && {
            value: passwordRepeatValue
          })}
        />
      </Box>

      <Box mt={2.5}>
        <Button
          variant='text'
          sx={{color: '#0F62FE'}}
          onClick={generatePassword}
        >
          Сгенерировать надежный пароль
        </Button>
        <Typography sx={{color: '#454F5B'}} variant='caption'>
          <ul style={{margin: '4px', padding: '0 0 0 20px'}}>
            <li>
              Не менее 9 символов
            </li>
            <li disablePadding>
              Заглавные и строчные буквы, цифры, пробелы и специальные символы
            </li>
            <li disablePadding>
              Пример надежного пароля: #?$dEz~1I
            </li>
          </ul>
        </Typography>
      </Box>
    </>
  )
}

