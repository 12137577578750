import {api} from '../api'
import {bidStatuses} from './bidStatuses'

export const bidApi = api.injectEndpoints({
  endpoints: (build) => ({

    fetchBidPage: build.query({
      query(params) {
        const status = bidStatuses[params.status]
        const search = params.search
        return {
          url: `/bid/`,
          method: 'GET',
          params: {
            // page_size: 25,
            full_response: false,
            page: params.page,
            endpoint_id: params.endpoint_id,
            price__lte: params.ltePrice,
            price__gte: params.gtePrice,
            registry_id: params.registry_id,
            [search?.type]: search?.value,
            ...status?.params,
            ...params.date,
            ...params.dateRange,
            ...params.fetchParams
          },
        }
      },
    }),

    fetchBid: build.query({
      query(params) {
        return {
          url: `/bid/${params.id}/`,
          method: 'GET',
        }
      },
    }),

    createBid: build.query({
      query(payload) {
        return {
          url: `/bid/`,
          method: 'POST',
          data: {
            ...payload,
            car_id: payload.car ? payload.car.id : undefined,
            seller_id: payload.seller ? payload.seller.id : undefined,
          },
        }
      },
    }),

    withdrawalRequestBid: build.query({
      query(payload) {
        return {
          url: `/withdrawal-request/`,
          method: 'POST',
          data: {
            bid_id: payload.bid.id,
            bid_requisite_id: payload.requisite.id,
          },
        }
      },
    }),

    updateBid: build.query({
      query(payload) {
        let seller_id
        if (payload.hasOwnProperty("seller_id")) {
          seller_id = payload.seller_id
        } else {
          seller_id = payload.seller ? payload.seller.id : undefined
        }
        const bid = {
          ...payload,
          ...(payload.item_list && {
            item_list: payload.item_list.map((item, index) => ({
              ...item,
              id: item.id,
              order: item.order,
              catalogue_item_id: item?.catalogue_item?.id ?? item?.catalogueItem?.id,
              weediness: item.weediness,
              humidity: item.humidity,
              quantity: item.quantity,
              weight_gross: item.weight_gross,
              weight_net: item.weight_net,
              weight_tare: item.weight_tare,
              catalogue_price_per_unit: item.catalogue_price_per_unit,
              price_per_unit: item.price_per_unit,
            })),
          }),
          car_id: payload.car ? payload.car.id : undefined,
          seller_id,
          endpoint: undefined,
          seller: undefined,
          car: undefined,
        }
        return {
          url: `/bid/${payload.id}/`,
          method: 'PUT',
          data: bid,
        }
      },
    }),

    updateBidId: build.query({
      query(payload) {
        let seller_id
        if (payload.hasOwnProperty("seller_id")) {
          seller_id = payload.seller_id
        } else {
          seller_id = payload.seller ? payload.seller.id : undefined
        }
        const bid = {
          ...payload,
          ...(payload.item_list && {
            item_list: payload.item_list.map((item, index) => ({
              ...item,
              id: item.id,
              order: item.order,
              catalogue_item_id: item?.catalogue_item?.id ?? item?.catalogueItem?.id,
              weediness: item.weediness,
              weight_gross: item.weight_gross,
              weight_net: item.weight_net,
              weight_tare: item.weight_tare,
              catalogue_price_per_unit: item.catalogue_price_per_unit,
              price_per_unit: item.price_per_unit,
            })),
          }),
          seller_id,
        }
        return {
          url: `/bid/${payload.id}/`,
          method: 'PUT',
          data: bid,
        }
      },
    }),

    documentNumber: build.query({
      query(payload) {
        const {bid, scrap_class_id, document_number, description} = payload

        const a = bid.document_number_list && Array.isArray(bid.document_number_list) ? bid.document_number_list.find(i => i.scrap_class_id === scrap_class_id) : null

        const url = a ? `/bid-document-number/${a.id}/` : `/bid-document-number/`
        const method = a ? `PUT` : `POST`
        const data = {}

        if (a) {
          data.document_number = document_number ?? null
          data.description = description ?? null
        } else {
          data.document_number = document_number ?? null
          data.description = description ?? null
          data.bid_id = bid.id
          data.scrap_class_id = scrap_class_id
        }

        return {
          url,
          method,
          data
        }
      },
    }),

    validate: build.query({
      query(payload) {
        return {
          url: `/bid/${payload.id}/validate/`,
          method: 'POST',
        }
      },
    }),

    searchSimilar: build.query({
      query(payload) {
        return {
          url: `/bid/${payload.id}/search_similar`,
          method: 'GET',
        }
      },
    }),
    createCopyBid: build.query({
      query(payload) {
        return {
          url: `/bid/${payload.id}/create_copy/`,
          method: 'POST',
        }
      },
    }),
  }),
})
