import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  employees: {
    loading: false,
    data: null,
  },
  updateEmployeeEndpoint: {
    loading: false,
    data: null,
  }
}

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    fetch(state, action) {

    },
    update(state, action) {

    },

    updateEmployeeEndpoint(state, action) {

    },

    setEmployeeUpdateEndpoint(state, action) {
      const {payload} = action
      state.updateEmployeeEndpoint.data = payload
    },
    setEmployees(state, action) {
      const {payload} = action
      state.employees.data = payload
    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
  },
})

const employeesReducer = employeesSlice.reducer

export {employeesSlice, employeesReducer}
