import {takeLatest, put, select} from "redux-saga/effects"
import {employeesSlice} from './employeesSlice'
import {network} from '../../utils/network'

function* fetch({payload}) {
  yield put(employeesSlice.actions.setLoading({type: 'employees', loading: true}))

  try {
    const {selectedEndpoint} = yield select(store => store.endpoint)

    const response = yield network.axios({
      method: 'GET',
      url: '/employee/',
      params: {
        page: payload.page,
        endpoint_id: selectedEndpoint.id,
      }
    })

    yield put(employeesSlice.actions.setEmployees({
      data: response.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(employeesSlice.actions.setLoading({type: 'employees', loading: false}))
  }
}

function* updateEmployeeEndpoint({payload}) {
  yield put(employeesSlice.actions.setLoading({type: 'updateEmployeeEndpoint', loading: true}))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/employee/${payload.id}/change_endpoint/`,
      data: payload
    })

    yield put(employeesSlice.actions.setEmployeeUpdateEndpoint({
      data: response.data,
    }))

  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(employeesSlice.actions.setLoading({type: 'updateEmployeeEndpoint', loading: false}))
  }
}

export function* employeesSagas() {
  yield takeLatest(employeesSlice.actions.fetch.type, fetch)
  yield takeLatest(employeesSlice.actions.updateEmployeeEndpoint.type, updateEmployeeEndpoint)
}
