// routes
import {paths} from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import {usePermission} from 'src/hooks/use-permission';
import {useSelector} from 'react-redux';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

// ----------------------------------------------------------------------

export function useNavData() {
  const {isCreator, isApprover} = usePermission()
  const bidBlocks = useSelector(state => state.app.settings.bidBlocks)
  const operations = {title: 'Операции', path: paths.dashboard.root, icon: icon('operations')}
  const registers = {title: 'Реестры', path: paths.registers.root, icon: icon('registers')}
  const employees = {title: 'Сотрудники', path: paths.employees.root, icon: icon('employees')}
  const transports = {title: 'Транспорт', path: paths.transports.root, icon: icon('transports')}
  const docTemplates = {title: 'Документы', path: paths.docTemplates.root, icon: icon('doc-templates')}

  const permissions = useSelector(store => store.user?.permissions)
  const selectedEndpointId = useSelector(store => store.endpoint.selectedEndpoint?.id)
  const endpointPermissions = permissions?.endpoints ? permissions.endpoints[selectedEndpointId] : {}

  const role = permissions?.user_data?.role

  const isAuto = bidBlocks?.auto?.display
  const items = []

  items.push(operations)

  if (isAuto) {
    items.push(transports)
  }

  if (isApprover || isCreator) {
    items.push(registers)
  }

  if (role === 'merchant-admin') {
    items.push(employees)
  }

  if (endpointPermissions?.can_create_document_template || endpointPermissions?.can_edit_document_template) {
    items.push(docTemplates)
  }

  const data = [
    {
      subheader: 'ОСНОВНЫЕ',
      items,

    },
  ]

  return data;
}

export function useSubNavData() {
  const {isCreator, isApprover} = usePermission()
  const userManual = {
    title: 'Руководство пользователя', path: "https://support.mozen.io/knowledge_base/",
    icon: icon('usermanual')
  }

  const items = []

  items.push(userManual)

  const data = [
    {
      items,
    },
  ]

  return data;
}
