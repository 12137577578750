// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css'

// ----------------------------------------------------------------------
import {Provider} from 'react-redux'
import {dispatch, store} from './store/store'
// routes
import Router from 'src/routes/sections'
// theme
import ThemeProvider from 'src/theme'
// hooks
import {useScrollToTop} from 'src/hooks/use-scroll-to-top'
// components
import ProgressBar from 'src/components/progress-bar'
import {MotionLazy} from 'src/components/animate/motion-lazy'
import {SettingsProvider, SettingsDrawer} from 'src/components/settings'
// auth
import {AuthProvider, AuthConsumer} from 'src/auth/context/jwt'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import {appSlice} from './store/app/appSlice'
//
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru'
import InfoMessages from './utils/InfoMessages'
import NotificationMessages from './utils/NotificationsMessages';
import {network} from './utils/network'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './externals/posthog'
import {useResponsive} from './hooks/use-responsive';
import UserDataModal from './components/user-data-modal/user-data-modal';
import {useOmnidesk} from './hooks/use-omnidesk';
import ChangePasswordRequired from './components/change-password-required/change-password-required';

// ----------------------------------------------------------------------

console.log(process.env.REACT_APP_VERSION + " от " + process.env.REACT_APP_VERSION_DESCRIPTION)

export default function App() {
  const smDown = useResponsive("down", "sm")
  useScrollToTop(smDown);
  useOmnidesk()
  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     network.axios({
  //       method: 'GET',
  //       url: '/car/0193b9ef-2916-71e9-3615-d2acc76d5062/',
  //       //0193beae-238a-77ce-6025-8322020ae356
  //       params: {
  //       }
  //     })
  //   }, 1e3)
  // }, [])

  useEffect(() => {
    network.navigate = navigate
    dispatch(appSlice.actions.restore())
  }, [])

  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ru}
      >
        <AuthProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SettingsDrawer />
                <ProgressBar />
                <AuthConsumer>
                  <ChangePasswordRequired />
                  <UserDataModal />
                  <InfoMessages />
                  <Router />
                  <NotificationMessages />
                </AuthConsumer>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </AuthProvider>
      </LocalizationProvider>
    </Provider>
  );
}
