import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormProvider from 'src/components/hook-form';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import PincodeLockIcon from 'src/assets/icons/pincode-lock-icon';
import GeneratePassword from 'src/pages/employees/components/GeneratePassword';
import {employeesApi} from 'src/store/employees/employeesApi';
import {useEffect} from 'react';

export default function ChangePasswordRequired() {
  const permissions = useSelector(store => store.user?.permissions)
  const [triggerUpdateEmployeeIdPassword, updateEmployeeIdPasswordQuery] = employeesApi.useLazyUpdateEmployeeIdPasswordQuery()

  const userData = permissions?.user_data
  const employeeId = userData?.employee_id
  const passwordChangeRequired = userData?.employee_password_change_required

  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const schema = Yup.object().shape({
    password: Yup.string().required('Поле обязательно к заполнению.'),
    passwordRepeat: Yup.string()
      .required('Поле обязательно к заполнению.')
      .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
  })
  // Soler5pisig1988
  useEffect(() => {
    if (permissions && passwordChangeRequired) {
      onOpen()
    }
  }, [permissions, passwordChangeRequired])

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      passwordRepeat: '',
    }
  })

  const {
    handleSubmit,
    formState: {isSubmitting},
    setValue,
    clearErrors,
  } = methods;

  const generatePassword = () => {
    const length = 12
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?'
    let password = ''
    for (let i = 0; i < length; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    setValue('password', password)
    clearErrors('password')
    setValue('passwordRepeat', password)
    clearErrors('passwordRepeat')
  }

  const onSubmit = handleSubmit(async (data) => {
    const {passwordRepeat, ...rest} = data

    try {
      const res = await triggerUpdateEmployeeIdPassword({
        id: employeeId,
        ...rest,
      })
      if (res.status === 'fulfilled') {
        onClose()
      }
    } catch (error) {
      console.log(error)
    }
  });

  return (
    <Dialog
      fullScreen={true}
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
        },
      }}
    >
      <Stack justifyContent='center' alignItems='center'>
        <Box sx={{maxWidth: '396px'}}>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <DialogTitle>
              <Stack>
                <Box align="center" mt={2} mb={1}>
                  <PincodeLockIcon />
                </Box>
                <Typography variant="h2" mb={2} align='center' sx={{fontWeight: 700}} >
                  Изменить пароль
                </Typography>

                <Typography variant="body2" align='center' color="#454F5B">
                  Придумайте новый пароль.
                  После смены пароля нужно будет
                  заново войти в систему.
                </Typography>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2.5} mt={1}>
                <GeneratePassword
                  loading={updateEmployeeIdPasswordQuery.isFetching}
                  generatePassword={generatePassword}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Сохранить
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </Box>
      </Stack>
    </Dialog>
  );
}
