import {alpha, IconButton} from '@mui/material'
import React from 'react'

export default function CustomIconButton({
  width,
  height,
  sx,
  ...rest
}) {
  return (
    <IconButton
      sx={[
        {
          backgroundColor: "#E8ECF1",
          height,
          width,
          borderRadius: "8px",
          '&:hover': {
            backgroundColor: (theme) =>
              alpha(theme.palette.grey[500], 0.24),
          },
        },
        {...sx}
      ]}
      {...rest}
    />
  )
}

