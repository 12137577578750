import PropTypes from 'prop-types';
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

// ----------------------------------------------------------------------

export default function RHFSwitch({name, helperText, labelSx, onChange, ...other}) {
  const {control} = useFormContext();

  const labelStyles = {
    ...(!other.label && {
      marginRight: 0
    }),
    ...labelSx
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        const handleChange = onChange ?? field.onChange

        return (
          <div>
            <FormControlLabel control={<Switch  {...field} onChange={handleChange} checked={field.value} />} {...other} sx={labelStyles} />

            {(!!error || helperText) && (
              <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
            )}
          </div>
        )
      }}
    />
  );
}

RHFSwitch.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string,
};
