import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen'

const DocTemplatesPage = lazy(() => import('src/pages/docTemplates/DocTemplatesPage'));

export const docTemplatesRoutes = [
  {
    path: '/doc-templates',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <DocTemplatesPage />,
      },
      {
        path: ':id',
        element: <DocTemplatesPage />,
      },
    ],
  },
];
